.payv-card {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 4px 102px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.payv-bal-card-title {
  color: #1b146480 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.payv-bal-card-credit {
  font-size: 48px;
}

.payv-bal-card-curr {
  font-size: 26px;
}
