.rv-card {
  padding: 15px;
  background-color: #f4f0f3;
  border: 2px solid #f4f0f3;
  border-radius: 8px;
  min-height: 238.56px;
}

.rv-card:hover {
  border: 2px solid var(--cc-prim-color);
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.rv-search-auto-complete * {
  background-color: #ffffff;
  border-radius: 8px !important;
  border-color: #ffffff;
}

.rv-map-container {
  position: relative;
}

.rv-search-auto-complete {
  position: absolute;
  inset-block-start: 15px;
  inset-inline-start: 15px;
}
