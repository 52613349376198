.pv-horizontal-divider {
  margin: 12px 0;
}

.pv-vertical-divider {
  border-color: #e4e4e4;
  height: 85%;
}

.pv-search-auto-complete * {
  border-radius: 8px !important;
  background-color: #f4f0f3;
}

.pv-product-form-item {
  margin-bottom: 0;
}

.pv-product-card {
  padding: 10px 10px 0 10px;
  border-radius: 8px 8px 0 0;
}

.pv-product-card-cover {
  position: relative;
}

.pv-product-card-cover-btn {
  position: absolute;
  bottom: 11px;
  left: 7px;
  border-radius: 20px;
}

.pv-save-btn {
  border-radius: 0 0 8px 8px;
}

.pv-select-tag {
  border-radius: 40px;
  padding: 2px 5px;
}

.pv-add-btn {
  height: 100%;
  background-color: #f8f8f8;
  padding-left: 10px;
  padding-right: 10px;
}

.pv-tabpane {
  color: #bababa;
  background: #f8f8f8;
  border: 1px solid #e7e7e7;
  padding: 8px 0;
}

.pv-tabpane:last-child {
  border-radius: 0 4px 4px 0;
}

.pv-tabpane:first-child {
  border-radius: 4px 0 0 4px;
}

.pv-active-tabpane {
  background: var(--cc-prim-color);
  color: #fff;
}

.pv-pi-brand-container {
  position: relative;
}

.pv-pi-brand-icon {
  position: absolute;
  z-index: 1;
  inset-block-start: 10.4px;
  inset-inline-start: 11px;
}

.pv-overlay-menu {
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.01),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;

  border: 1px solid rgba(151, 151, 151, 0.3);
  border-radius: 4px;
}

.pv-menu-item:hover {
  background-color: #f4f0f3;
}

.pv-modal-sortable-container {
  background: rgba(27, 20, 100, 0.05);
  border-radius: 4px;
  padding: 16px 20px;
}

.pv-modal-sortable-element {
  padding: 14px 18px;
  background-color: #fff;
}

.pv-modal-choices-container {
  background: rgba(27, 20, 100, 0.05);
  border-radius: 8px;
  padding: 16px 20px;
}

.pv-modal-choices-color-container {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-inline-end: 5px;
  cursor: pointer;
}

.pv-oq-divider {
  border-left: 1px solid rgb(222, 222, 222);
}

.pv-product-imgs-img-container {
  border: 1px solid #e7e7e7;
  border-radius: 2px;
}

.pv-product-imgs-dragger-container {
  position: relative;
}

.pv-product-imgs-actions-container {
  background: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  padding: 8px 15px;
}

.pv-tabs {
  padding-inline-end: 30px;
}

.pv-mr-5 {
  margin-inline-end: 5px;
}

.pv-mr-20 {
  margin-inline-end: 20px;
}

.pv-rotate-45 > svg {
  transform: rotate(-90deg);
}

.pv-align-start {
  align-items: flex-start;
}

.pv-rc {
  color: #ee0004;
}

.pv-align-center {
  display: flex;
  align-items: center;
}

.pv-transparent {
  opacity: 0.65;
}

/* Overrides */
.pv-product-form .ant-input-group-addon,
.pv-options-form .ant-input-group-addon,
.pv-tabs-container .ant-input-group-addon {
  background-color: #f8f8f8;
}

.pv-pi-brand-container .ant-select-selection-item,
.pv-pi-brand-container .ant-select-selection-placeholder {
  padding-inline-start: 30px !important;
}

.pv-modal-with-header .ant-modal-header {
  background: #f4f0f3;
}
