.cbv {
  height: calc(100vh - 64px);
}

.cbv-left {
  padding-block-start: 65px;
  padding-inline-start: 65px;
}

.cbv-mb-45 {
  margin-block-end: 45px;
}
