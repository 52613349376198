.lv {
  height: 100vh;
  overflow: hidden;
}

.lv-left {
  flex-direction: column;
}

.lv-right {
  position: relative;
}

.lv-bg-box {
  position: absolute;
  inset-block-start: 4%;
  inset-inline-end: 0;
  z-index: -1;

  width: 92%;
}

.lv-mb-45 {
  margin-block-end: 45px !important;
}
