.sv-card {
  padding: 15px;
  margin-block-start: 10px;
  margin-block-end: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 102px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.sv-select-txt {
  font-weight: normal;
  margin-block-start: 5px;
}

.sv-vertical-center {
  display: flex;
  align-items: center;
}

.sv-mr-15 {
  margin-inline-end: 15px;
}

.sv-mr-10 {
  margin-inline-end: 10px;
}

.sv-bc {
  color: #009fdb;
}

.sv-rc {
  color: #ee0004;
}
