.sv {
  height: 100vh;
}

.sv-left {
  padding-block-start: 65px;
  padding-inline-start: 65px;
}

.sv-right {
  position: relative;
}

.sv-bg-box {
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  z-index: -1;

  width: 92%;
}

.sv-mb-55 {
  margin-block-end: 55px !important;
}
