.vsv-card {
  width: 95%;
  margin: 0 auto;
}

.vsv-card-desc {
  text-align: left;
  padding: 0 24px;
  cursor: default;
  float: left;
}

.vsv-mr-15 {
  margin-inline-end: 15px;
}
