.ov-card {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  width: 172px;
  min-height: 119.98px;
  padding: 15px 20px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.ov-search {
  width: 400px !important;
  height: 42px;
  border-radius: 8px !important;
}

.ov-search,
.ov-search input[type='text'] {
  background-color: #f4f0f3 !important;
  padding: 0 10px !important;
}

.ov-search-icon {
  margin-inline-end: 10px;
}

.ov-tag-green {
  color: #0f766e;
  background-color: #ccfbf1;
  border: 0.5px solid #0f766e;
}

.ov-tag-red {
  color: #ef4444;
  background-color: #fee2e2;
  border: 0.5px solid #ef4444;
}

.ov-tag-gold {
  color: #f59e0b;
  background-color: #fef3c7;
  border: 0.5px solid #f59e0b;
}

.ov-br-16 {
  border-radius: 16px;
}
