.cc-p-ut_wrapper {
  position: relative;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;

  color: var(--cc-sec-color, #1b1464);
}

.cc-p-ut_line {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 59%;

  width: 100%;
  height: 10px;
  background: rgba(117, 36, 111, 0.37);
}
