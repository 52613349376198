.cc-layout {
  transition: all 0.4s;
}

.cc-layout-open {
  margin-inline-start: 256px;
}

.cc-layout-collapsed {
  margin-inline-start: 80px;
}

.cc-layout-sidebar {
  height: 100vh;
  padding-block-start: 20px;
  border-inline-end: 1px solid #e4e4e4;

  position: fixed !important;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 999;
}

.cc-layout-sidebar-logo {
  display: flex;
  align-items: center;

  padding-inline-start: 24px;
}

.cc-layout-sidebar-logo-title {
  margin-inline-start: 5px;
}

.cc-layout-sidebar.ant-layout-sider-collapsed .cc-layout-sidebar-logo {
  margin-block-end: 10px;
}

.cc-layout-sidebar.ant-layout-sider-collapsed .cc-layout-sidebar-logo-title {
  display: none;
}

.cc-layout-sidebar-menu svg {
  font-size: 1.375rem;
}

.cc-layout-navbar,
.cc-layout-nbv-navbar {
  padding: 0 20px !important;
  border-block-end: 1px solid #e4e4e4 !important;

  position: fixed;
  inset-block-start: 0;
  z-index: 999;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cc-layout-navbar {
  width: calc(100% - 256px) !important;
}

.cc-layout-collapsed > .cc-layout-navbar {
  width: calc(100% - 80px) !important;
}

.cc-layout-navbar-icon {
  color: var(--cc-sec-color) !important;
  margin-inline-start: 40px;
}

.cc-layout-navbar-search {
  width: 400px !important;
  height: 62% !important;
  border-radius: 38px !important;
}

.cc-layout-navbar-search,
.cc-layout-navbar-search input[type='text'] {
  background-color: #f4f0f3 !important;
  padding: 0 10px !important;
}

.cc-layout-navbar-search-icon {
  margin-inline-end: 10px;
}

.cc-layout-navbar-avatar {
  margin-inline-start: 40px !important;
  width: 36px !important;
  height: 36px !important;

  background-color: var(--cc-sec-color) !important;
}

.cc-layout-content,
.cc-layout-nbv-content {
  margin-block-start: 64px;
}

.cc-layout-content {
  padding: 24px;
}

.cc-layout-navbar-lng-change > .ant-dropdown-menu-title-content {
  display: flex;
  justify-content: center;
}

.cc-layout-navbar-lng-change-switch.ant-switch,
.cc-layout-navbar-lng-change-switch.ant-switch-checked {
  background-color: var(--cc-sec-color) !important;
  width: 70%;
}
