.odv-card {
  background: #ffffff;
  border: 1px solid rgba(231, 231, 231, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 10px;
}

.odv-search {
  width: 400px !important;
  height: 42px;
  border-radius: 8px !important;
}

.odv-search,
.odv-search input[type='text'] {
  background-color: #f4f0f3 !important;
  padding: 0 10px !important;
}

.odv-search-icon {
  margin-inline-end: 10px;
}

.odv-status-overlay {
  width: 240px;
}

.odv-status-overlay-item {
  padding: 10px;
}

.odv-status-overlay-btn {
  height: 32px;
}

.odv-ant-card {
  border-radius: 8px;
  min-height: 215.14px;
}

.odv-tag {
  border-radius: 16px;
}

.odv-divider {
  margin: 0;
}

.odv-bg-fff {
  background-color: #fff;
}

.odv-ml-5 {
  margin-inline-start: 5px;
}

/* Overrides */
.odv-ant-card .ant-card-head {
  background-color: #f4f0f3;
}
