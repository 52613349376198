.delv-card {
  border: 1px solid rgba(151, 151, 151, 0.15);
  border-radius: 4px;
}

.delv-card-up {
  height: 100px;
}

.delv-card-down {
  background: #f4f0f3;
  border-top: 1px solid rgba(151, 151, 151, 0.15);
  padding: 7px 10px;
}

.delv-ml-5 {
  margin-inline-start: 5px;
}
