.cc-layout-sidebar .ant-menu-item-group-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--cc-prim-color);
}

.cc-layout-sidebar.ant-layout-sider-collapsed .ant-menu-item-group-title,
.cc-layout-sidebar.ant-layout-sider-collapsed .ant-menu-item-divider {
  display: none;
}

.cc-layout-sidebar.ant-layout-sider-collapsed .ant-menu-item {
  margin-block-end: 8px;
}

.cc-layout-sidebar .ant-menu-item-group-title {
  padding-inline-start: 24px;
}

.cc-layout-sidebar .ant-menu-item-selected {
  font-weight: 600 !important;
}

.cc-layout-sidebar .ant-menu-inline,
.cc-layout-sidebar .ant-menu-vertical {
  border: none !important;
}

.cc-layout-sidebar .ant-menu-inline .ant-menu-item::after {
  border: none !important;
  border-inline-end: 1px solid #e4e4e4 !important;
}

.cc-layout-sidebar .ant-menu-item-divider {
  background-color: #f0f3f6 !important;
  width: 68.75%;
  margin-inline-start: 24px !important;
}

.cc-layout-sidebar.ant-layout-sider {
  transition: all 0.4s;
}

.cc-layout .ant-select-arrow {
  width: fit-content;
  height: fit-content;
}

.cc-lang-select * {
  border: none !important;
}

.cc-lang-select .ant-select-selection-item {
  color: var(--cc-sec-color);
}

.ant-switch-checked {
  background-color: var(--cc-prim-color) !important;
}
