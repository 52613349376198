:root {
  --cc-prim-color: #75246f;
  --cc-sec-color: #1b1464;
  --cc-grey-color: #979797;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #fcfcff !important;
}

body[dir='rtl'] {
  overflow-x: hidden;
}

input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='number']::placeholder {
  font-weight: 400;
}

input[type='text'],
input[type='email'],
input[type='number'] {
  font-weight: 500;
}

textarea {
  font-weight: 500;
}

textarea::placeholder {
  font-weight: 400;
}

@import '~antd/dist/antd.css';

/* unity */
#unity-container.unity-mobile {
  width: 100%;
  height: 100%;
}

#unity-canvas {
  background: #030024;
}

.unity-mobile #unity-canvas {
  width: 100%;
  height: 100%;
}

#unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

#unity-progress-bar-empty {
  width: 141px;
  height: 18px;
  margin-top: 10px;
  background: url('../assets/images/progress-bar-empty-dark.png') no-repeat
    center;
}

#unity-progress-bar-full {
  width: 0%;
  height: 18px;
  margin-top: 10px;
  background: url('../assets/images/progress-bar-full-dark.png') no-repeat
    center;
}

#unity-footer {
  position: relative;
}

#unity-mobile-warning {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}
