.dv-card {
  padding: 15px;
  background-color: #f4f0f3;
  border: 2px solid #f4f0f3;
  border-radius: 8px;
  min-height: 100%;
}

.dv-card:hover {
  border: 2px solid var(--cc-prim-color);
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.dv-card-img {
  max-height: 185px;
  object-fit: contain;
}
