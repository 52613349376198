.hv-card,
.hv-card-2 {
  background-color: #f4f0f3;
  border-radius: 8px;
}

.hv-card {
  min-height: 344.88px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 30px 15px 15px 15px;
}

.hv-card-2 {
  padding: 10px 0;
}

.hv-card-2-left {
  height: 100%;
  background-color: rgba(252, 252, 255, 0.75);
}

.hv-card-footer {
  display: flex;
  align-items: center;
}

.hv-business-card {
  padding: 15px;
  background-color: #f4f0f3;
  border: 2px solid #f4f0f3;
  border-radius: 8px;
}

.hv-business-card-not-selected:hover {
  border: 2px solid var(--cc-prim-color);
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15);
}

.hv-business-card-selected {
  border: 2px solid var(--cc-prim-color);
}

.hv-fz-20 {
  font-size: 1.25rem;
}

.hv-ml-10 {
  margin-inline-start: 10px;
}

.hv-mt-25 {
  margin-block-start: 25px;
}
