/* Colors */
.cc-pc {
  color: var(--cc-prim-color) !important;
}

.cc-sc {
  color: var(--cc-sec-color) !important;
}

.cc-gc {
  color: var(--cc-grey-color) !important;
}

.cc-wc {
  color: #ffffff !important;
}

/* Spacing (Margin) */
.cc-mb-5 {
  margin-block-end: 5px !important;
}

.cc-mb-10 {
  margin-block-end: 10px !important;
}

.cc-mb-15 {
  margin-block-end: 15px !important;
}

.cc-mb-20 {
  margin-block-end: 20px !important;
}

.cc-mb-25 {
  margin-block-end: 25px !important;
}

/* Sizing (Width & Height) */
.cc-w-50 {
  width: 50% !important;
}

.cc-w-100 {
  width: 100% !important;
}

/* Font (Size, Weight) */
.cc-fz-10 {
  font-size: 0.625rem !important;
}

.cc-fz-12 {
  font-size: 0.75rem !important;
}

.cc-fz-14 {
  font-size: 0.875rem !important;
}

.cc-fz-16 {
  font-size: 1rem !important;
}

.cc-fz-18 {
  font-size: 1.125rem !important;
}

.cc-fz-20 {
  font-size: 1.25rem !important;
}

.cc-fz-22 {
  font-size: 1.375rem !important;
}

.cc-fz-24 {
  font-size: 1.5rem !important;
}

.cc-fw-400 {
  font-weight: 400 !important;
}

.cc-fw-500 {
  font-weight: 500 !important;
}

.cc-fw-600 {
  font-weight: 600 !important;
}

/* Placement */
.cc-center-items {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cc-z-1000 {
  z-index: 1000 !important;
}

/* Transform */
.cc-rotate-180 {
  transform: rotate(180deg) !important;
}

/* Custom */
.cc-clickable {
  cursor: pointer;
}

.cc-custom-btn {
  background-color: #f4f0f3 !important;
  border-color: #f4f0f3 !important;
}

.cc-custom-btn-2 {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.cc-custom-input {
  background-color: #f4f0f3 !important;
}

.cc-select-arrow {
  color: #868e96;
}

.cc-dragger-title {
  color: #8c8888;
}

.cc-dragger {
  background: #f4f0f3 !important;
  border: 1px dashed rgba(117, 36, 111, 0.4) !important;
}

.cc-dragger-icon {
  font-size: 2rem;
  color: var(--cc-prim-color);
}

.cc-dragger-preview-item {
  cursor: pointer;
}

.cc-dragger-container > span {
  display: block;
}

.cc-dragger-container-h-100 > span {
  height: 100%;
}

.cc-loading-screen {
  width: 100vw;
  height: 100vh;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cc-loading-screen > *:not(:last-child) {
  margin-block-end: 10px;
}

.cc-item-with-mh {
  margin-block-end: 0;
}

.cc-item-with-mh .ant-col.ant-form-item-control {
  min-height: 75px;
}
